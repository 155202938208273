var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('div',[_vm._v(" Assets List "),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","disabled":!_vm.isAuthenticated || _vm.assets.loading},on:{"click":_vm.getAssets}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiReload))])],1)],1)]),_c('v-card-text',[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"footer-props":{
          'items-per-page-options': [10, 10],
          'disable-items-per-page': true,
          'disable-pagination': _vm.assets.loading
        },"items":_vm.assets.list,"options":_vm.assets.options,"page":_vm.assets.meta.page,"server-items-length":_vm.assets.meta.total || 0,"loading":_vm.assets.loading,"no-data-text":'No data available',"loading-text":'Loading, pls wait'},on:{"update:options":function($event){return _vm.$set(_vm.assets, "options", $event)},"pagination":function($event){return _vm.changePagination($event)}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.startReview(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"18"}},[_vm._v(_vm._s(_vm.icons.mdiFileEdit))]),_c('span',[_vm._v("Review Asset")])],1)],1)],1)],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }